import { handleActions } from 'redux-actions'
import { lineCampaignFormAction as action } from 'actions'

const initialState = {
  item: {},
  isFetching: false,
  isSubmitting: false,
  isSendingTestMail: false,
}

export const lineCampaignForm = handleActions({
  [action.fetchLineCampaign]: (state) => ({
    ...state,
    isFetching: true,
  }),
  [action.fetchLineCampaignDone]: (state, action) => ({
    ...state,
    isFetching: false,
    item: action.payload,
  }),
  [action.CreateLineCampaign]: (state) => ({
    ...state,
    isSubmitting: true,
  }),
  [action.CreateLineCampaignSuccess]: (state) => ({
    ...state,
    isSubmitting: false,
  }),
  [action.CreateLineCampaignFailure]: (state) => ({
    ...state,
    isSubmitting: false,
  }),
  [action.UpdateLineCampaign]: (state) => ({
    ...state,
    isSubmitting: true,
  }),
  [action.UpdateLineCampaignSuccess]: (state) => ({
    ...state,
    isSubmitting: false,
  }),
  [action.UpdateLineCampaignFailure]: (state) => ({
    ...state,
    isSubmitting: false,
  }),
  [action.clearLineCampaign]: (state) => ({
    ...initialState,
    item: initialState.item,
    isSubmitting: false,
  }),
}, initialState)
