import React from 'react'
import { connect } from 'react-redux'
import { Formik, Form } from 'formik'
import {
  Button, FormGroup, Input, InputGroupText, FormText, UncontrolledTooltip,
} from 'reactstrap'
import * as Yup from 'yup'
import {
  Loading, FieldSingle, FieldGroup, Label, HintIcon, InputGroupAddon,
} from 'lib/components'
import { mailSenderInfoAction } from 'actions'
import ValidateDomainButton from 'components/parts/common/ValidateDomainButton'

const validationSchema = Yup.object().shape({
  fromName: Yup.string(),
  replyToEmail: Yup.string(),
  prohibitFrom: Yup.string().test('prohibitFrom', '開始時刻を設定してください', function(value) {
    const { prohibitTo } = this.parent
    if (prohibitTo && !value) return false
    return true
  }),
  prohibitTo: Yup.string().test('prohibitTo', '終了時刻を設定してください', function(value) {
    const { prohibitFrom } = this.parent
    if (prohibitFrom && !value) return false
    return true
  }),
  prohibitFromMobile: Yup.string().test('prohibitFromMobile', '開始時刻を設定してください', function(value) {
    const { prohibitToMobile } = this.parent
    if (prohibitToMobile && !value) return false
    return true
  }),
  prohibitToMobile: Yup.string().test('prohibitToMobile', '終了時刻を設定してください', function(value) {
    const { prohibitFromMobile } = this.parent
    if (prohibitFromMobile && !value) return false
    return true
  }),
  prohibitFromLine: Yup.string().test('prohibitFromLine', '開始時刻を設定してください', function(value) {
    const { prohibitToLine } = this.parent
    if (prohibitToLine && !value) return false
    return true
  }),
  prohibitToLine: Yup.string().test('prohibitToLine', '終了時刻を設定してください', function(value) {
    const { prohibitFromLine } = this.parent
    if (prohibitFromLine && !value) return false
    return true
  }),
  generalUrlParam: Yup.string().nullable(),
  useMultipart: Yup.bool(),
  sendToCarrier: Yup.bool(),
})

export const MailSenderInfoEditForm = ({ mailSenderInfo, dispatch }) => {
  const item = mailSenderInfo.item
  return (
    <Loading isLoading={mailSenderInfo.isFetching}>
      <Formik
        initialValues={{
          fromName: item.fromName || '',
          replyToEmail: item.replyToEmail || '',
          prohibitFrom: item.prohibitFrom || '',
          prohibitTo: item.prohibitTo || '',
          prohibitFromMobile: item.prohibitFromMobile || '',
          prohibitToMobile: item.prohibitToMobile || '',
          prohibitFromLine: item.prohibitFromLine || '',
          prohibitToLine: item.prohibitToLine || '',
          generalUrlParam: item.generalUrlParam || '',
          useMultipart: item.useMultipart,
          sendToCarrier: item.sendToCarrier,
        }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          dispatch(mailSenderInfoAction.submitUpdateMailSenderInfo(values))
        }}
      >
        {({ values, setFieldValue }) => (
          <Form noValidate>
            { console.log(values) }
            <legend className="mb-0">メール送信元情報</legend>
            <FormText className="mb-3" tag="span">キャンペーンの作成時にデフォルトで入力される値を設定できます。</FormText>
            <fieldset>
              <FormGroup>
                <Label>差出人名</Label>
                <FieldSingle name="fromName" type="text" />
              </FormGroup>
              <FormGroup>
                <Label>ReplyToアドレス</Label>
                <FieldGroup>
                  <Input name="replyToEmail" type="text" />
                  <InputGroupAddon addonType="append">
                    <ValidateDomainButton email={values.replyToEmail} />
                  </InputGroupAddon>
                </FieldGroup>
                <FormText>お客様がリタゲメールに返信した場合の返信先です</FormText>
              </FormGroup>
            </fieldset>
            <legend className="mb-0">配信停止設定<HintIcon id="prohibitPointTooltip" className="ml-1" style={{ 'font-size': '14px' }} /></legend>
            <FormText className="mb-3" tag="span">強制的にメール配信を停止する時間です。停止時間に配信される予定だったメールは、停止時間が明けた後に配信されます。</FormText>
            <fieldset>
              <FormGroup>
                <Label>配信停止時間設定（PCメールアドレス）</Label>
                <FieldGroup>
                  <Input name="prohibitFrom" type="time" />
                  <InputGroupAddon addonType="middle">
                    <InputGroupText>〜</InputGroupText>
                  </InputGroupAddon>
                  <Input name="prohibitTo" type="time" />
                </FieldGroup>
                <SpanNote from={values.prohibitFrom} to={values.prohibitTo} />
              </FormGroup>
              <FormGroup>
                <Label>配信停止時間設定（携帯メールアドレス）</Label>
                <FieldGroup>
                  <Input name="prohibitFromMobile" type="time" />
                  <InputGroupAddon addonType="middle">
                    <InputGroupText>〜</InputGroupText>
                  </InputGroupAddon>
                  <Input name="prohibitToMobile" type="time" />
                </FieldGroup>
                <SpanNote from={values.prohibitFromMobile} to={values.prohibitToMobile} />
              </FormGroup>
              <FormGroup>
                <Label>配信停止時間設定（LINE）</Label>
                <FieldGroup>
                  <Input name="prohibitFromLine" type="time" />
                  <InputGroupAddon addonType="middle">
                    <InputGroupText>〜</InputGroupText>
                  </InputGroupAddon>
                  <Input name="prohibitToLine" type="time" />
                </FieldGroup>
                <SpanNote from={values.prohibitFromLine} to={values.prohibitToLine} />
              </FormGroup>
            </fieldset>
            <legend className="mb-0">共通URLパラメータ設定</legend>
            <FormText className="mb-3" tag="span">
              キャンペーン内のリンクに自動で付与するURLパラメータを設定できます。外部のアクセス解析ソフトの計測用パラメータの設定などにご利用ください。<br />
              ※全キャンペーンに反映される設定です。キャンペーン作成時に個別に設定する方法もございます。
            </FormText>
            <fieldset>
              <FormGroup>
                <Label>共通URLパラメータ</Label>
                <FieldSingle name="generalUrlParam" type="text" placeholder="param1=value1&param2=value2" />
              </FormGroup>
            </fieldset>
            <legend className="mb-3">マルチパートメール設定</legend>
            <fieldset>
              <FormGroup check>
                <FieldSingle
                  name="useMultipart"
                  type="checkbox"
                  option="テキストパートを使用する"
                  onChange={(e) => { !e.target.checked && setFieldValue('sendToCarrier', false) }}
                />
                <FormText className="mt-0">チェックされていない場合はHTMLパートのみが配信されます。チェックするとHTMLパートとテキストパートの両方が配信されます。</FormText>
              </FormGroup>
              <FormGroup check>
                <FieldSingle
                  name="sendToCarrier"
                  type="checkbox"
                  option="携帯キャリアドメイン宛配信を行う"
                  disabled={!values.useMultipart}
                />
                <FormText className="mt-0">携帯キャリアドメイン宛配信ではテキストパートのみを送信するため、「テキストパートを使用する」をONにする必要があります。</FormText>
                <FormText>携帯キャリアドメイン宛配信を利用するためにはSPFレコードを正しく設定する必要があります。</FormText>
              </FormGroup>
            </fieldset>
            <div className="text-center"><Button color="primary" type="submit" size="lg">保存</Button></div>
            <Tooltips />
          </Form>
        )}
      </Formik>
    </Loading>
  )
}

const Tooltips = () => (
  <>
    <UncontrolledTooltip placement="right" target="prohibitPointTooltip">
      ️夜間にメールが届いて欲しくないユーザーが多いと予想される場合は深夜帯を停止時間に設定するなど、サイトのユーザー属性に応じて設定することがおすすめです！
    </UncontrolledTooltip>
  </>
)

const SpanNote = ({ from, to }) => {
  if(!from || !to) {
    return (
      <FormText>配信停止設定はありません</FormText>
    )
  }
  if(to < from) {
    return (
      <FormText>{from} ~ 翌{to}の間は配信停止します</FormText>
    )
  }
  return (
    <FormText>{from} ~ {to}の間は配信停止します</FormText>
  )
}

const select = ({ mailSenderInfo }) => ({ mailSenderInfo })

export default connect(select)(MailSenderInfoEditForm)
