import React from 'react'
import { connect } from 'react-redux'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'

import { FormGroup } from 'reactstrap'
import { Label, FieldSingle, PasswordStrengthBadge } from 'lib/components'
import * as customYupValidation from 'lib/yupValidation'
import { authAction } from 'actions'
import { generalConstant as gc, companySystemOrderConstant as csoc } from 'config/constants'

Yup.addMethod(Yup.string, 'equalTo', customYupValidation.equalTo)

const validationSchema = Yup.object().shape({
  user: Yup.object().shape({
    email: Yup.string().email().required(),
    password: Yup.string().required(),
    passwordConfirmation: Yup.string().equalTo(Yup.ref('password'), 'パスワードが一致していません').required(),
    lastName: Yup.string().required(),
    firstName: Yup.string().required(),
  }),
  company: Yup.object().shape({
    name: Yup.string().required(),
    domain: Yup.string().matches(/([A-Za-z0-9][A-Za-z0-9-]{1,61}[A-Za-z0-9]\.)+[A-Za-z]+/).required(),
    siteName: Yup.string(),
    siteUrl: Yup.string().url(),
    phoneNumber: Yup.string(),
    postalCode: Yup.string(),
    prefecturalCode: Yup.string(),
    address1: Yup.string(),
    address2: Yup.string(),
  }),
  companySystemOrder: Yup.object().shape({
    siteType: Yup.string().required(),
    systemType: Yup.string().required(),
    sitePv: Yup.string().required(),
    customerDataCount: Yup.string().required(),
    itemDataCount: Yup.string().required(),
    freeField: Yup.string(),
  }),
})

export const SignUpForm = ({ dispatch }) => (
  <Formik
    initialValues={{
      user: {
        email: '',
        password: '',
        passwordConfirmation: '',
        lastName: '',
        firstName: '',
      },
      company: {
        name: '',
        domain: '',
        siteName: '',
        phoneNumber: '',
        postalCode: '',
        prefectureCode: undefined,
        address1: '',
        address2: '',
      },
      companySystemOrder: {
        siteType: 'ec',
        systemType: 'c21_sell_side_solution',
        sitePv: 'pv_under_2500k',
        customerDataCount: 'customer_under_10k',
        itemDataCount: 'item_under_50k',
        freeField: '',
      },
    }}
    validationSchema={validationSchema}
    onSubmit={(values) => {
      dispatch(authAction.submitSignUp(values))
    }}
  >
    {({ values }) => (
      <Form noValidate>
        <legend className="mb-3">ログイン情報</legend>
        <fieldset>
          <FormGroup>
            <Label require>メールアドレス</Label>
            <FieldSingle name="user[email]" type="text" placeholder="例）naviplus@naviplus.co.jp" />
          </FormGroup>
          <FormGroup>
            <Label require>パスワード</Label>
            <FieldSingle name="user[password]" type="password" />
            <PasswordStrengthBadge password={values.user.password} minLength={8} />
          </FormGroup>
          <FormGroup>
            <Label require>パスワード（確認用）</Label>
            <FieldSingle name="user[passwordConfirmation]" type="password" />
          </FormGroup>
        </fieldset>
        <legend className="mb-3">担当者情報</legend>
        <fieldset>
          <div className="mb-4">
            <h5>ユーザ情報は次の目的で利用します</h5>
            <ol>
              <li>作成したキャンペーンをテスト送信するため</li>
              <li>ご利用の当該サービスに係る各種ご連絡をするため</li>
              <li>株式会社DGビジネステクノロジーに係る各種ご連絡をするため</li>
            </ol>
          </div>
          <FormGroup>
            <Label require>姓</Label>
            <FieldSingle name="user[lastName]" type="text" placeholder="例）山田" />
          </FormGroup>
          <FormGroup>
            <Label require>名</Label>
            <FieldSingle name="user[firstName]" type="text" placeholder="例）花子" />
          </FormGroup>
        </fieldset>
        <legend className="mb-3">企業情報</legend>
        <fieldset>
          <FormGroup>
            <Label require>企業名</Label>
            <FieldSingle name="company[name]" type="text" placeholder="例）ナビプラス株式会社" />
          </FormGroup>
          <FormGroup>
            <Label require>ドメイン（メール差出人用）</Label>
            <FieldSingle name="company[domain]" type="text" placeholder="例）naviplus.co.jp" />
          </FormGroup>
          <FormGroup>
            <Label>導入サイト名</Label>
            <FieldSingle name="company[siteName]" type="text" placeholder="例）ナビプラスネットショップ" />
          </FormGroup>
          <FormGroup>
            <Label>サイトURL</Label>
            <FieldSingle name="company[siteUrl]" type="text" placeholder="例）http://www.naviplus.co.jp/" />
          </FormGroup>
          <FormGroup>
            <Label>電話番号</Label>
            <FieldSingle name="company[phoneNumber]" type="text" placeholder="例）03-5456-8017" />
          </FormGroup>
          <FormGroup>
            <Label>郵便番号</Label>
            <FieldSingle name="company[postalCode]" type="text" placeholder="例）150-0021" />
          </FormGroup>
          <FormGroup>
            <Label>都道府県</Label>
            <FieldSingle name="company[prefectureCode]" type="select" blank options={gc.prefectures} />
          </FormGroup>
          <FormGroup>
            <Label>住所１</Label>
            <FieldSingle name="company[address1]" type="text" placeholder="例）東京都渋谷区恵比寿西1-7-7" />
          </FormGroup>
          <FormGroup>
            <Label>住所２</Label>
            <FieldSingle name="company[address2]" type="text" placeholder="例）EBSビル 8階" />
          </FormGroup>
        </fieldset>
        <legend className="mb-3">システム情報</legend>
        <fieldset>
          <FormGroup>
            <Label require>ウェブサイトのカテゴリ</Label>
            <FieldSingle name="companySystemOrder[siteType]" type="select" options={csoc.siteTypes} />
          </FormGroup>
          <FormGroup>
            <Label require>利用システム</Label>
            <FieldSingle name="companySystemOrder[systemType]" type="select" options={csoc.systemTypes} />
          </FormGroup>
          <FormGroup>
            <Label require>月間アクセス数（サイト全体）</Label>
            <FieldSingle name="companySystemOrder[sitePv]" type="select" options={csoc.sitePvs} />
          </FormGroup>
          <FormGroup>
            <Label require>保有会員データ件数</Label>
            <FieldSingle name="companySystemOrder[customerDataCount]" type="select" options={csoc.customerDataCounts} />
          </FormGroup>
          <FormGroup>
            <Label require>保有アイテムデータ件数</Label>
            <FieldSingle name="companySystemOrder[itemDataCount]" type="select" options={csoc.itemDataCounts} />
          </FormGroup>
          <FormGroup>
            <Label>自由記入欄</Label>
            <FieldSingle name="companySystemOrder[freeField]" type="textarea" />
          </FormGroup>
        </fieldset>
        <button className="btn btn-block btn-primary mt-3" type="submit">アカウントを作成</button>
      </Form>
    )}
  </Formik>
)

export default connect()(SignUpForm)
