import React from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { Table, Button, CardBody, CardFooter, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import { Swal, Pagination } from 'lib/components'
import { generalConstant as gc, lineCampaignConstant as lcc } from 'config/constants'
import { arraysToObject } from 'lib/utils'
import { lineCampaignsAction } from 'actions/lineCampaignsAction'

export const LineCampaignList = ({ lineCampaigns, limit, offset, dispatch }) => {
  const targetItems = lineCampaigns.sort((a, b) => a.updatedAt < b.updatedAt ? 1 : -1)
    .filter((_, index) => (index >= offset && index < offset + limit))
    .filter(cam => cam.status !== 'finished')
    .map(cam => (
      <tr key={cam.id}>
        <td>
          <img src="/line.png" width="100px" alt="line" />
        </td>
        <td className="campaign-overview">
          <h5>{cam.name}</h5>
          <CampaignBadges campaign={cam} />
          <span>アクション発生から<strong>{displayedScheduleTime(cam.schedule)}後</strong>に配信</span>
          <p className="mb-0">
            <span className="ascii">{moment(cam.updatedAt).format('YYYY/MM/DD HH:mm')}</span>に更新
          </p>
        </td>
        <td className="text-center w-15">
          <Button tag={Link} to={`/line_campaign/${cam.campaignType}/${cam.id}`} color="secondary" disabled={cam.stage === 'finished'}>編集</Button>
        </td>
        <td className="text-center w-10">
          <FinishCampaignButton campaign={cam} dispatch={dispatch} />
        </td>
        <td className="text-center w-10">
          <CampaignStatusDropdownButton campaign={cam} dispatch={dispatch} />
        </td>
      </tr>
    ))

  return (
    <>
      <CardBody>
        <Table responsive>
          <tbody className="no-thead">
            {targetItems}
          </tbody>
        </Table>
      </CardBody>
      <CardFooter>
        <Pagination
          totalItemCount={targetItems.length}
          limit={limit}
        />
      </CardFooter>
    </>
  )
}

const displayedScheduleTime = (schedule) => {
  // scheduleが60分未満の場合は分、60分以上24時間未満の場合は時間、24時間以上の場合は日に変換する
  if (schedule < 60) return `${schedule}分`
  if (schedule < 60 * 24) return `${(schedule / 60)}時間`
  return `${(schedule / 60 / 24)}日`
}

const gender = arraysToObject(lcc.gender, 1, 0)
const customerType = arraysToObject(lcc.customerTypes, 1, 0)
const campaignType = arraysToObject(lcc.campaignTypes, 2, 0)

const CampaignBadges = ({ campaign }) => (
  <p className="mb-2">
    <span className="badge badge-purple">{campaignType[campaign.campaignType]}</span>
    <span className="badge badge-purple">{gender[campaign.gender]}</span>
    <span className="badge badge-purple">{customerType[campaign.customerType]}</span>
  </p>
)

const CampaignStatusDropdownButton = ({ campaign, dispatch }) => {
  const { statusText, statusColor, actionText, nextStatus } = getCampaignStatusValues(campaign.status)

  const handleClick = (e) => { dispatch(lineCampaignsAction.submitChangeLineCampaignStatus({ ...campaign, status: nextStatus})) }
  return (
    <UncontrolledDropdown>
      <DropdownToggle
        caret
        disabled={campaign.status === 'finished'}
        color={statusColor}
      >{statusText}
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem onClick={handleClick}>{actionText}</DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  )
}

const getCampaignStatusValues = (status) => {
  switch (status) {
  case 'in_preparation':
    return { statusText: '準備中', statusColor: 'success', actionText: '開始する', nextStatus: 'in_the_middle' }
  case 'in_the_middle':
    return { statusText: '配信中', statusColor: 'info', actionText: '停止する', nextStatus: 'stopped' }
  case 'stopped':
    return { statusText: '停止中', statusColor: 'warning', actionText: '開始する', nextStatus: 'in_the_middle' }
  case 'finished':
    return { statusText: '配信終了', statusColor: 'danger', actionText: '開始する', nextStatus: undefined }
  default:
    return { statusText: 'エラー', statusColor: 'danger', actionText: 'エラー', nextStatus: undefined }
  }
}

const FinishCampaignButton = ({ campaign, dispatch }) => {
  return (
    <Swal
      options={gc.finishCampaignSwalOptions}
      color="danger"
      callback={(isConfirm) => { isConfirm && dispatch(lineCampaignsAction.submitFinishLineCampaign({...campaign, status: 'finished'})) }}
      disabled={campaign.status === 'finished'}
    >
     終了
    </Swal>
  )
}
