import { handleActions } from 'redux-actions'
import { lineCampaignsAction as action } from 'actions'

const initialState = {
  items: [],
  isFetching: false,
  isSubmitting: false,
}

export const lineCampaigns = handleActions({
  [action.fetchLineCampaigns]: (state) => ({
    ...state,
    isFetching: true,
  }),
  [action.fetchLineCampaignsDone]: (state, action) => ({
    ...state,
    isFetching: false,
    items: action.payload,
  }),
  [action.submitChangeLineCampaignStatus]: (state) => ({
    ...state,
    isSubmitting: true,
  }),
  [action.submitChangeLineCampaignStatusSuccess]: (state, action) => ({
    ...state,
    isSubmitting: false,
    items: state.items.map(item => (item.id === action.payload.id ? action.payload : item)),
  }),
  [action.submitChangeLineCampaignStatusFailure]: (state, action) => ({
    ...state,
    isSubmitting: false,
  }),
  [action.submitFinishLineCampaign]: (state) => ({
    ...state,
  }),
  [action.submitFinishLineCampaignSuccess]: (state, action) => ({
    ...state,
    items: state.items.map(item => (item.id === action.payload.id ? action.payload : item)),
  }),
  [action.submitFinishLineCampaignFailure]: (state) => ({
    ...state,
  }),
}, initialState)
