import { handleActions } from 'redux-actions'
import { transitionReportAction as action } from 'actions'

const initialState = {
  item: {},
  isFetching: false,
}

export const transitionReport = handleActions({
  [action.fetchTransitionReport]: (state) => ({
    ...state,
    isFetching: true,
  }),
  [action.fetchTransitionReportDone]: (state, action) => ({
    ...state,
    isFetching: false,
    item: action.payload,
  }),
}, initialState)
