import React from 'react'

import AdminLoginForm from 'components/parts/auth/AdminLoginForm'

const LoginPage = () => (
  <div className="block-center mt-4 wd-xl">
    <div className="card card-flat">
      <div className="card-header text-center bg-dark">
        <img className="block-center rounded logo-small" src="/img/series_logo_white.png" alt="Logo" />
      </div>
      <div className="card-body">
        <AdminLoginForm />
      </div>
    </div>
    <div className="p-3 text-center">
      <span className="mr-2">Copyright</span>
      <span className="mr-2">&copy;</span>
      <span className="mr-2">DG Business Technology, Inc., a Digital Garage company. All rights reserved</span>
    </div>
  </div>
)

export default LoginPage
