import React from 'react'
import { connect } from 'react-redux'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { purchasedLineCampaignConstant as plcc, generalConstant as gc } from 'config/constants'
import { lineCampaignFormAction } from 'actions'
import CampaignMailCodeHelp from 'components/parts/campaign/CampaignMailCodeHelp'
import {
  Loading,
  FieldCode,
  Label,
  HelpIcon,
  HintIcon,
} from 'lib/components'
import {
  Button,
  FormGroup,
  Input,
  FormText,
  UncontrolledCollapse,
  UncontrolledTooltip,
  InputGroup,
} from 'reactstrap'

const validationSchema = Yup.object().shape({
  name: Yup.string().required().max(255),
  campaignType: Yup.string().required(),
  status: Yup.string().required(),
  purchaseCount: Yup.string().required(),
  customerType: Yup.string().required(),
  gender: Yup.string().required(),
  schedule: Yup.number().required(),
  deliveryIntervalMinutes: Yup.number().required(),
  isStoppedIfEmailExists: Yup.bool().required(),
  isStoppedIfPurchased: Yup.bool().required(),
  urlParams: Yup.string().max(255),
  messages: Yup.string().required().max(250 * 1024, '入力が多すぎます（最大250KBまで）'),
  testAddress: Yup.string().matches(/^U[0-9a-f]{32}$/),
})

export const PurchasedLineCampaignForm = ({
  isFetching, isSubmitting, lineCampaign, dispatch
}) => {
  let isTestDeliverySubmit = false

  return (
    <Loading isLoading={isFetching}>
      <Formik
        initialValues={{
          name: lineCampaign.name || '',
          campaignType: 'purchased',
          status: lineCampaign.status || 'in_preparation',
          purchaseCount: lineCampaign.purchaseCount || 'all_customers',
          customerType: lineCampaign.customerType || 'all_types',
          gender: lineCampaign.gender || 'all_gender',
          schedule: lineCampaign.schedule ? lineCampaign.schedule : 30,
          deliveryIntervalMinutes: lineCampaign.deliveryIntervalMinutes ? lineCampaign.deliveryIntervalMinutes : 24 * 60,
          isStoppedIfEmailExists: lineCampaign.isStoppedIfEmailExists || false,
          isStoppedIfPurchased: lineCampaign.isStoppedIfPurchased || false,
          urlParams: lineCampaign.urlParams || '',
          messages: lineCampaign.messages || '',
        }}
        validationSchema={validationSchema}
        validate={(values) => {
          if (isTestDeliverySubmit && !values.testAddress) {
            return { testAddress: 'テスト送信時は必須項目です' }
          }
          return {}
        }}
        onSubmit={(values) => {
          if (lineCampaign.id) {
            dispatch(lineCampaignFormAction.updateLineCampaign({ ...values, id: lineCampaign.id }))
          } else {
            dispatch(lineCampaignFormAction.createLineCampaign({ ...values }))
          }
        }}
      >
        {({ submitForm }) => (
          <Form noValidate>
            <fieldset>
              <FormGroup>
                <Label require>キャンペーン名称</Label>
                <Field name="name" type="text" className="form-control" />
                <ErrorMessage name="name" component="div" className="d-block invalid-feedback" />
              </FormGroup>
            </fieldset>
            <legend className="mb-0">
              ターゲット顧客属性<HintIcon id="segmentPointTooltip" className="ml-1" style={{ 'fontSize': '14px' }} />
            </legend>
            <UncontrolledTooltip placement="right" target="segmentPointTooltip">
              ️施策に適した傾向を持つ顧客を配信対象に指定することで、成果指標（開封率・クリック率・完了率）を引き上げる効果が期待できます！
            </UncontrolledTooltip>
            <FormText className="mb-3" tag="span">
              セグメント別配信の設定メニューです。
            </FormText>
            <fieldset>
              <FormGroup>
                <Label require>購入履歴</Label>
                <Field type="select" name="purchaseCount" as={Input} className="custom-select">
                  {plcc.purchaseCounts.map(([label, value], index) => (
                    <option key={index} value={value}>
                      {label}
                    </option>
                  ))}
                </Field>
              </FormGroup>
              <FormGroup>
                <Label require>会員種別</Label>
                <Field type="select" name="customerType" as={Input} className="custom-select">
                  {plcc.customerTypes.map(([label, value], index) => (
                    <option key={index} value={value}>
                      {label}
                    </option>
                  ))}
                </Field>
              </FormGroup>
              <FormGroup>
                <Label require>性別</Label>
                <Field type="select" name="gender" as={Input} className="custom-select">
                  {plcc.gender.map(([label, value], index) => (
                    <option key={index} value={value}>
                      {label}
                    </option>
                  ))}
                </Field>
              </FormGroup>
              <FormGroup check className="mb-0">
                <Field
                  type="checkbox"
                  name="isStoppedIfEmailExists"
                  id="isStoppedIfEmailExistsCheck"
                  as={Input}
                />
                <Label for="isStoppedIfEmailExistsCheck" check>メールアドレスを取得している顧客には送らない</Label>
              </FormGroup>
              <FormText className="mt-0">
                ※メール連携キャンペーンを利用時にのみ使用する設定です。
              </FormText>
            </fieldset>
            <legend className="mb-3">配信スケジュール設定</legend>
            <fieldset>
              <FormGroup>
                <Label require>ターゲットアクションからの経過時間</Label>
                <HelpIcon id="scheduleTooltip" className="ml-1" />
                <UncontrolledTooltip placement="right" target="scheduleTooltip">
                  お客さまの⾏動の発⽣後どのくらいの時間にLINE配信するかを設定します。
                </UncontrolledTooltip>
                <Field type="select" name="schedule" as={Input} className="custom-select">
                  {plcc.scheduleTimes.map(([label, value], index) => (
                    <option key={index} value={value}>
                      {label}
                    </option>
                  ))}
                </Field>
              </FormGroup>
              <FormGroup>
                <Label require>一度LINEを送信した顧客への最低配信間隔</Label>
                <HelpIcon id="deliveryIntervalMinutesTooltip" className="ml-1" />
                <UncontrolledTooltip placement="right" target="deliveryIntervalMinutesTooltip">
                  同じような内容のLINEが連続で配信されないように、一定の期間中配信を停止するための設定です。
                </UncontrolledTooltip>
                <Field type="select" name="deliveryIntervalMinutes" as={Input} className="custom-select">
                  {plcc.deliveryIntervalMinutes.map(([label, value], index) => (
                    <option key={index} value={value}>
                      {label}
                    </option>
                  ))}
                </Field>
              </FormGroup>
              <FormGroup className="mb-0" check>
                <Field
                  type="checkbox"
                  name="isStoppedIfPurchased"
                  id="isStoppedIfPurchasedCheck"
                  as={Input}
                />
                <Label for="isStoppedIfPurchasedCheck" check>ターゲットアクションからの経過時間までに購入した場合は配信をしない</Label>
                <HelpIcon id="isStoppedIfPurchasedTooltip" className="ml-1" />
                <UncontrolledTooltip placement="right" target="isStoppedIfPurchasedTooltip">
                  購⼊されたお客さまの場合、LINEを送るか停⽌するかを設定します。
                </UncontrolledTooltip>
              </FormGroup>
            </fieldset>
            <legend className="mb-3">その他条件設定</legend>
            <fieldset>
              <FormGroup>
                <Label>URLパラメータ</Label>
                <HelpIcon id="urlParamTooltip" className="ml-1" />
                <UncontrolledTooltip placement="right" target="urlParamTooltip">
                  キャンペーン内のリンクに自動で付与するURLパラメータを設定できます。<br />
                  外部のアクセス解析ソフトの計測用パラメータの設定などにご利用ください。
                </UncontrolledTooltip>
                <Field name="urlParams" placeholder="param1=value2&param2=value2" className="form-control" />
                <ErrorMessage name="urlParams" component="div" className="d-block invalid-feedback" />
              </FormGroup>
            </fieldset>
            <legend className="mb-3">LINE設定</legend>
            <fieldset className="mb-5">
              <FormGroup>
                <Label require>LINEメッセージ内容</Label>
                <FieldCode name="messages" />
              </FormGroup>
              <div className="mb-2">
                <Button
                  color="secondary"
                  id="codeHelpToggler3"
                  onClick={() => { }}
                >
                  {' '}
                  コードのヘルプを表示する
                </Button>
              </div>
              <UncontrolledCollapse toggler="#codeHelpToggler3" className="mb-5">
                <CampaignMailCodeHelp campaignType='purchase' messageType='line' />
              </UncontrolledCollapse>
            </fieldset>
            <FormGroup>
              <Label>テスト送信先 LINE ID</Label>
              <InputGroup>
                <Field name="testAddress" type="text" as={Input} />
                <Button
                  color="info"
                  onClick={(e) => {
                    isTestDeliverySubmit = true
                    submitForm()
                  }}
                >
                テスト送信
                </Button>
              </InputGroup>
              <FormText>カンマ区切りで複数入力可能</FormText>
              <FormText>※メッセージ文面に表示されるアイテム情報などは、ランダムに抽出されたものが表示されます。</FormText>
              <FormText>
                <a href={gc.lineIdCheckUrl} target="_blank" rel="noopener noreferrer">
                  LINE IDを確認する（クリックするとLINE IDが表示されます）
                </a>
              </FormText>
            </FormGroup>
            <div className="text-center">
              <Button
                color="primary"
                size="lg"
                disabled={isSubmitting}
                onClick={(e) => {
                  isTestDeliverySubmit = false
                  submitForm()
                }}
              >
                保存
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Loading>
  )
}


const select = ({ lineCampaignForm }) => {
  return {
    lineCampaign: { ...lineCampaignForm.item },
    isFetching: lineCampaignForm.isFetching,
    isSubmitting: lineCampaignForm.isSubmitting,
  }
}

export default connect(select)(PurchasedLineCampaignForm)
