export default {
  campaignTypes: [
    ['カゴ落ち', 1, 'cart_abandoned'],
    ['ブラウザ放棄', 2, 'browser_abandoned'],
    ['会員登録完了', 3, 'registered'],
    ['購入完了', 4, 'purchased'],
  ],
  gender: [
    ['性別を問わない', 'all_gender'],
    ['男性のみ対象', 'male_only'],
    ['女性のみ対象', 'female_only'],
  ],
  customerTypes: [
    ['全ての顧客', 'all_types'],
    ['種別１', 'type1'],
    ['種別２', 'type2'],
    ['種別３', 'type3'],
    ['種別４', 'type4'],
    ['種別５', 'type5'],
    ['種別６', 'type6'],
    ['種別７', 'type7'],
    ['種別８', 'type8'],
    ['種別９', 'type9'],
    ['種別10', 'type10'],
  ],
  purchaseCounts: [
    ['全ての顧客', 'all_customers'],
    ['購入履歴なし', 'not_purchased'],
    ['購入履歴が1回の顧客', 'purchased_once'],
    ['購入履歴が2回以上の顧客', 'purchased_more_than_once'],
  ],
  deliveryStatus: [
    ['全て', 'all'],
    ['配信中', 'on'],
    ['配信中以外', 'off'],
  ],
}
