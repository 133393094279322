import { createActions } from 'redux-actions'

const FETCH_LINE_CAMPAIGN = 'FETCH_LINE_CAMPAIGN'
const FETCH_LINE_CAMPAIGN_DONE = 'FETCH_LINE_CAMPAIGN_DONE'
const CREATE_LINE_CAMPAIGN = 'CREATE_LINE_CAMPAIGN'
const CREATE_LINE_CAMPAIGN_SUCCESS = 'CREATE_LINE_CAMPAIGN_SUCCESS'
const CREATE_LINE_CAMPAIGN_FAILURE = 'CREATE_LINE_CAMPAIGN_FAILURE'
const UPDATE_LINE_CAMPAIGN = 'UPDATE_LINE_CAMPAIGN'
const UPDATE_LINE_CAMPAIGN_SUCCESS = 'UPDATE_LINE_CAMPAIGN_SUCCESS'
const UPDATE_LINE_CAMPAIGN_FAILURE = 'UPDATE_LINE_CAMPAIGN_FAILURE'
const CLEAR_LINE_CAMPAIGN = 'CLEAR_LINE_CAMPAIGN'

export const lineCampaignFormAction = createActions(
  FETCH_LINE_CAMPAIGN,
  FETCH_LINE_CAMPAIGN_DONE,
  CREATE_LINE_CAMPAIGN,
  CREATE_LINE_CAMPAIGN_SUCCESS,
  CREATE_LINE_CAMPAIGN_FAILURE,
  UPDATE_LINE_CAMPAIGN,
  UPDATE_LINE_CAMPAIGN_SUCCESS,
  UPDATE_LINE_CAMPAIGN_FAILURE,
  CLEAR_LINE_CAMPAIGN,
)
