import lineCampaignConstant from './lineCampaignConstant'

export default {
  ...lineCampaignConstant,
  scheduleTimes: [
    ['15分後に送信', 15],
    ['30分後に送信', 30],
    ['45分後に送信', 45],
    ['1時間後に送信', 60],
    ['2時間後に送信', 2 * 60],
    ['4時間後に送信', 4 * 60],
    ['8時間後に送信', 8 * 60],
    ['12時間後に送信', 12 * 60],
    ['1日後に送信', 24 * 60],
    ['2日後に送信', 2 * 24 * 60],
    ['3日後に送信', 3 * 24 * 60],
    ['4日後に送信', 4 * 24 * 60],
    ['5日後に送信', 5 * 24 * 60],
    ['6日後に送信', 6 * 24 * 60],
    ['7日後に送信', 7 * 24 * 60],
  ],
  deliveryIntervalMinutes: [
    ['1日間送信しない', 24 * 60],
    ['2日間送信しない', 2 * 24 * 60],
    ['3日間送信しない', 3 * 24 * 60],
    ['7日間送信しない', 7 * 24 * 60],
    ['14日間送信しない', 14 * 24 * 60],
  ],
  deliveryIntervalMinutesForTestAccount: [
    ['停止しない', 0],
    ['1日間送信しない', 24 * 60],
    ['2日間送信しない', 2 * 24 * 60],
    ['3日間送信しない', 3 * 24 * 60],
    ['7日間送信しない', 7 * 24 * 60],
    ['14日間送信しない', 14 * 24 * 60],
  ],
  deliveryIntervalMinutesAfterPurchased: [
    ['停止しない', 0],
    ['1日間送信しない', 24 * 60],
    ['2日間送信しない', 2 * 24 * 60],
    ['3日間送信しない', 3 * 24 * 60],
    ['7日間送信しない', 7 * 24 * 60],
    ['14日間送信しない', 14 * 24 * 60],
    ['30日間送信しない', 30 * 24 * 60],
    ['60日間送信しない', 60 * 24 * 60],
    ['90日間送信しない', 90 * 24 * 60],
    ['180日間送信しない', 180 * 24 * 60],
  ],
  purchaseCounts: [
    ['全ての顧客', 'all_customers'],
    ['購入履歴がない顧客', 'not_purchased'],
    ['購入履歴が1回の顧客', 'purchased_once'],
    ['購入履歴が2回以上の顧客', 'purchased_more_than_once'],
  ],
}
