import { createActions } from 'redux-actions'

const FETCH_LINE_CAMPAIGNS = 'FETCH_LINE_CAMPAIGNS'
const FETCH_LINE_CAMPAIGNS_DONE = 'FETCH_LINE_CAMPAIGNS_DONE'

const SUBMIT_CHANGE_LINE_CAMPAIGN_STATUS = 'SUBMIT_CHANGE_LINE_CAMPAIGN_STATUS'
const SUBMIT_CHANGE_LINE_CAMPAIGN_STATUS_SUCCESS = 'SUBMIT_CHANGE_LINE_CAMPAIGN_STATUS_SUCCESS'
const SUBMIT_CHANGE_LINE_CAMPAIGN_STATUS_FAILURE = 'SUBMIT_CHANGE_LINE_CAMPAIGN_STATUS_FAILURE'

const SUBMIT_FINISH_LINE_CAMPAIGN = 'SUBMIT_FINISH_LINE_CAMPAIGN'
const SUBMIT_FINISH_LINE_CAMPAIGN_SUCCESS = 'SUBMIT_FINISH_LINE_CAMPAIGN_SUCCESS'
const SUBMIT_FINISH_LINE_CAMPAIGN_FAILURE = 'SUBMIT_FINISH_LINE_CAMPAIGN_FAILURE'


export const lineCampaignsAction = createActions(
  FETCH_LINE_CAMPAIGNS,
  FETCH_LINE_CAMPAIGNS_DONE,
  SUBMIT_CHANGE_LINE_CAMPAIGN_STATUS,
  SUBMIT_CHANGE_LINE_CAMPAIGN_STATUS_SUCCESS,
  SUBMIT_CHANGE_LINE_CAMPAIGN_STATUS_FAILURE,
  SUBMIT_FINISH_LINE_CAMPAIGN,
  SUBMIT_FINISH_LINE_CAMPAIGN_SUCCESS,
  SUBMIT_FINISH_LINE_CAMPAIGN_FAILURE
)
