import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'

import {
  Alert, Button, FormGroup, Input, FormText, UncontrolledCollapse, UncontrolledTooltip,
} from 'reactstrap'
import {
  Loading, FieldSingle, Label, InputGroupAddon, FieldGroup, MailPreviewButton, HelpIcon, HintIcon,
} from 'lib/components'
import { prospectsCampaignAction, mailPreviewAction } from 'actions'
import CampaignMailCodeHelp from 'components/parts/campaign/CampaignMailCodeHelp'
import CampaignHtmlFields from 'components/parts/campaign/CampaignHtmlFields'
import ManualLink from 'components/parts/manual/ManualLink'
import ValidateDomainButton from 'components/parts/common/ValidateDomainButton'

const validationSchema = Yup.object().shape({
  name: Yup.string().required(),
  eventIds: Yup.array(),
  fromName: Yup.string().notIncludeAngleBrackets().required(),
  fromAddress: Yup.string().required(),
  subject: Yup.string().required(),
  previewText: Yup.string(),
  urlParam: Yup.string(),
  htmlBody: Yup.string(),
  textBody: Yup.string(),
  htmlHeader: Yup.string(),
  style: Yup.string(),
})

export const ProspectsCampaignForm = ({
  prospectsCampaign, company, companyCampaignTemplateValue, mailSenderInfo, event, item, submitAction, dispatch,
}) => {
  const initialEventIds = 'events' in item ? item.events.map(ev => (`${ev.id}`)) : []
  return (
    <Loading isLoading={prospectsCampaign.isFetching || mailSenderInfo.isFetching || event.isFetching}>
      <Formik
        initialValues={{
          name: item.name || '',
          eventIds: initialEventIds,
          fromName: item.fromName || '',
          fromAddress: item.fromAddress || '',
          subject: item.subject || '',
          previewText: item.previewText || '',
          urlParam: item.urlParam || '',
          htmlBody: item.htmlBody || '',
          textBody: item.textBody || '',
          htmlHeader: item.htmlHeader || '',
          stage: item.stage || 'in_preparation',
          style: item.style || '',
          testAddress: '',
        }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          let { eventIds, ...submitValues } = values
          eventIds = eventIds || []
          submitValues.events = event.items.filter(ev => (eventIds.some(x => (`${x}` === `${ev.id}`))))
          if (submitAction === 'update') {
            dispatch(prospectsCampaignAction.submitUpdateProspectsCampaign({ id: item.id, ...submitValues }))
          } else {
            dispatch(prospectsCampaignAction.submitCreateProspectsCampaign({ ...submitValues }))
          }
        }}
      >
        {({ values, setFieldValue }) => (
          <Form noValidate>
            { prospectsCampaign.error && <Alert color="danger">{prospectsCampaign.error}</Alert> }
            <fieldset>
              <FormGroup>
                <Label require>キャンペーン名称</Label>
                <FieldSingle name="name" type="text" />
              </FormGroup>
              <FormGroup>
                <Label>イベント選択</Label>
                <FieldGroup>
                  { event.items.map(ev => (
                    <Input
                      name="eventIds"
                      type="multicheckbox"
                      key={`event_${ev.id}`}
                      option={ev.eventable.name}
                      value={ev.id}
                    />
                  ))}
                </FieldGroup>
              </FormGroup>
            </fieldset>
            <legend className="mb-3">メール設定</legend>
            <fieldset>
              <FormGroup>
                <Label require>差出人名</Label>
                <FieldSingle name="fromName" type="text" />
              </FormGroup>
              <FormGroup>
                <Label require>Reply-toアドレス</Label>
                <HelpIcon id="fromAddressTooltip" className="ml-1" />
                <UncontrolledTooltip placement="right" target="fromAddressTooltip">
                  SPFレコード設定が必須となります。「SPFチェック」ボタンを押すと、入力したアドレスのSPFレコードが正しく設定できているか確認することができます。
                </UncontrolledTooltip>
                <FieldGroup>
                  <Input name="fromAddress" type="text" />
                  <InputGroupAddon addonType="append">
                    <ValidateDomainButton email={values.fromAddress} />
                  </InputGroupAddon>
                </FieldGroup>
                <FormText>
                  お客様がリタゲメールに返信した場合の返信先メールアドレスです。
                  <Link to="/campaign_common_setting">全体の初期設定はこちら</Link>
                </FormText>
                <FormText>
                  <ManualLink filename="mansys_dns">
                    DNS設定について
                  </ManualLink>
                </FormText>
              </FormGroup>
              <FormGroup>
                <Label require>件名</Label>
                <HintIcon id="subjectPointTooltip" className="ml-1" />
                <UncontrolledTooltip placement="right" target="subjectPointTooltip">
                  15〜30文字がおすすめです。適切に設定することで開封率がアップする可能性があります！
                </UncontrolledTooltip>
                <FieldSingle name="subject" type="text" />
              </FormGroup>
              <FormGroup>
                <Label>プレビューテキスト</Label>
                <HelpIcon id="previewTextTooltip" className="ml-1" />
                <UncontrolledTooltip placement="right" target="previewTextTooltip">
                  メール本⽂の概要（ダイジェスト）として受信箱内に表⽰されるテキストです。<br />
                  ※設定されてない場合、メール本⽂のテキスト情報を先頭から順に読み取り表⽰します。
                </UncontrolledTooltip>
                <HintIcon id="previewTextPointTooltip" className="ml-1" />
                <UncontrolledTooltip placement="right" target="previewTextPointTooltip">
                  統⼀性をもたせ不信感を減らし開封率を引き上げる効果が期待できます！
                </UncontrolledTooltip>
                <FieldSingle name="previewText" type="text" />
              </FormGroup>
              <FormGroup>
                <Label>URLパラメータ</Label>
                <HelpIcon id="urlParamTooltip" className="ml-1" />
                <UncontrolledTooltip placement="right" target="urlParamTooltip">
                  キャンペーン内のリンクに自動で付与するURLパラメータを設定できます。<br />
                  外部のアクセス解析ソフトの計測用パラメータの設定などにご利用ください。
                </UncontrolledTooltip>
                <FieldSingle name="urlParam" type="text" placeholder="param1=value2&param2=value2" />
              </FormGroup>
            </fieldset>
            <legend className="mb-3">メール内容</legend>
            <fieldset>
              <CampaignHtmlFields
                company={company}
                companyCampaignTemplateValue={companyCampaignTemplateValue}
                formType="prospects"
                submitAction={submitAction}
                values={values}
                setFieldValue={setFieldValue}
                dispatch={dispatch}
              />
              <FormGroup>
                <Label>テキストメール用本文</Label>
                <FieldSingle name="textBody" type="textarea" style={{ minHeight: 200 }} />
                <FormText>
                  <Link to="/campaign_common_setting">テキストパートの配信設定はこちら</Link>
                </FormText>
              </FormGroup>
              <div className="mb-5">
                <Button
                  color="secondary"
                  id="codeHelpToggler2"
                  onClick={() => {}}
                >
                  {' '}
コードのヘルプを表示する
                </Button>
              </div>
              <UncontrolledCollapse toggler="#codeHelpToggler2" className="mb-5">
                <CampaignMailCodeHelp />
              </UncontrolledCollapse>
              <div className="mb-5">
                <MailPreviewButton
                  color="info"
                  onClickCallback={() => { dispatch(mailPreviewAction.submitCreateMailPreview({ ...values, _type: 'prospects' })) }}
                >
メールのプレビュー
                </MailPreviewButton>
              </div>
              <FormGroup>
                <Label>テストメール送信先アドレス</Label>
                <FieldGroup>
                  <Input name="testAddress" type="text" />
                  <InputGroupAddon addonType="append">
                    <Button
                      color="info"
                      onClick={(e) => { dispatch(prospectsCampaignAction.submitSendProspectsCampaignTestMail(values)) }}
                    >
テストメール送信
                    </Button>
                  </InputGroupAddon>
                </FieldGroup>
                <FormText>カンマ区切りで複数入力可能</FormText>
                <FormText>※メール文面に表示されるアイテム情報などは、ランダムに抽出されたものが表示されます。</FormText>
              </FormGroup>
            </fieldset>
            <div className="text-center"><Button color="primary" type="submit" size="lg">保存</Button></div>
          </Form>
        )}
      </Formik>
    </Loading>
  )
}

const select = ({
  prospectsCampaign, company, companyCampaignTemplateValue, mailSenderInfo, campaignTemplate, event,
}, { campaignId, templateId, submitAction }) => {
  let item
  let mailContentOverride = {
    fromAddress: mailSenderInfo.item.replyToEmail,
    fromName: mailSenderInfo.item.fromName,
  }
  let campaignContentOverride
  if (templateId) {
    const template = campaignTemplate.items.filter(obj => (obj.id === templateId))[0] || {}
    campaignContentOverride = {
      subject: template.subject,
      htmlBody: template.htmlBody,
      textBody: template.textBody,
      htmlHeader: template.htmlHeader,
      style: template.style,
    }
  }
  if (campaignId) {
    campaignContentOverride = prospectsCampaign.items.find(obj => obj.id === campaignId) || {}
  }
  // コピーして新規作成の場合はステージを「準備中」にする
  if (campaignId && submitAction === 'create') {
    campaignContentOverride.stage = 'in_preparation'
  }
  item = { ...mailContentOverride, ...campaignContentOverride }
  return {
    prospectsCampaign, company, companyCampaignTemplateValue, mailSenderInfo, event, item, submitAction,
  }
}

export default connect(select)(ProspectsCampaignForm)
