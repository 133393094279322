import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import ContentWrapper from 'components/layout/ContentWrapper'
import {
  Row, Col, Card, CardBody,
} from 'reactstrap'
import {
  adminPermissionAction,
} from 'actions'
import PurchasedLineCampaignForm from 'components/parts/lineCampaign/PurchasedLineCampaignForm'
import CartAbandonedLineCampaignForm from 'components/parts/lineCampaign/CartAbandonedLineCampaignForm'

const formComponentMapping = {
  purchased: PurchasedLineCampaignForm,
  cart_abandoned: CartAbandonedLineCampaignForm
}

export const LineCampaignNewPage = ({ dispatch, match }) => {
  const campaignType = match.params.campaign_type
  const FormComponent = formComponentMapping[campaignType]

  useEffect(() => {
    dispatch(adminPermissionAction.fetchAdminPermission())
  }, [dispatch])

  return (
    <ContentWrapper>
      <div className="content-heading">LINEキャンペーン新規作成</div>
      <Row>
        <Col xl={12}>
          <Card className="card-default">
            <CardBody>
              <FormComponent />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </ContentWrapper>
  )
}


export default connect()(LineCampaignNewPage)
