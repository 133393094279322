import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import ContentWrapper from 'components/layout/ContentWrapper'
import {
  Row, Col, Card, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem,
} from 'reactstrap'
import { Loading } from 'lib/components'
import { deserializeFromQueryString } from 'lib/utils'
import { generalConstant as gc } from 'config/constants'
import { lineCampaignsAction } from 'actions/lineCampaignsAction'
import { LineCampaignList } from 'components/parts/lineCampaign/LineCampaignList'

export const LineCampaignListPage = ({ dispatch, lineCampaigns, isFetching, location }) => {
  useEffect(() => {
    dispatch(lineCampaignsAction.fetchLineCampaigns())
  }, [dispatch])
  const queryObj = deserializeFromQueryString(location.search) || {}
  const page = Number(queryObj.page) || 1

  return (
    <ContentWrapper>
      <div className="content-heading">
        <div>LINEキャンペーン一覧</div>
        <div className="ml-3">
          <UncontrolledDropdown>
            <DropdownToggle caret>新規作成</DropdownToggle>
            {
              /*
                のちのちCampaignListPageと同じようにテンプレート一覧に飛ばすようになる可能性があるが、
                現状は直接新規作成ページへ飛ばす
              */
            }
            <DropdownMenu>
              <DropdownItem
                onClick={() => { dispatch(push('/line_campaign/cart_abandoned/new')) }}
              >
                カート放棄キャンペーン
              </DropdownItem>
              <DropdownItem
                onClick={() => { dispatch(push('/line_campaign/purchased/new')) }}
              >
                購入完了キャンペーン
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      </div>
      <Row>
        <Col xl={9}>
          <Card className="card-default">
            <Loading isLoading={isFetching}>
              <LineCampaignList
                lineCampaigns={lineCampaigns}
                limit={gc.campaignItemsLimit}
                offset={(page - 1) * gc.campaignItemsLimit}
                dispatch={dispatch}
              />
            </Loading>
          </Card>
        </Col>
      </Row>
    </ContentWrapper>
  )
}

const select = ({ lineCampaigns }) => {
  return {
    lineCampaigns: [...lineCampaigns.items],
    isFetching: lineCampaigns.isFetching,
  }
}
export default connect(select)(LineCampaignListPage)
