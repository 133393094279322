import React from 'react'

import SignUpForm from 'components/parts/auth/SignUpForm'

const SignUpPage = () => (
  <div className="block-center mt-4" style={{ width: '450px' }}>
    <div className="card card-flat">
      <div className="card-header text-center bg-dark">
        <img className="block-center rounded logo-small" src="/img/series_logo_white.png" alt="Logo" />
      </div>
      <div className="card-body">
        <SignUpForm />
      </div>
    </div>
    <div className="p-3 text-center">
      <span className="mr-2">Copyright</span>
      <span className="mr-2">&copy;</span>
      <span className="mr-2">DG Business Technology, Inc., a Digital Garage company. All rights reserved</span>
    </div>
  </div>
)

export default SignUpPage
