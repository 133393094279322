import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import ContentWrapper from 'components/layout/ContentWrapper'
import { Card, CardBody, } from 'reactstrap'
import { useMountEffect } from 'lib/hooks'
import { deserializeFromQueryString } from 'lib/utils'

import { reportConstant as rc } from 'config/constants'
import {
  comparisonReportAction,
  abandonedCampaignAction,
  completedCampaignAction,
  contextualCampaignAction,
  popupAction,
  prospectsCampaignAction,
} from 'actions'
import ComparisonReportConditionForm from 'components/parts/report/ComparisonReportConditionForm'
import ComparisonReportTable from 'components/parts/report/ComparisonReportTable'
import ComparisonReportCsvDownloadButton from 'components/parts/report/ComparisonReportCsvDownloadButton'
import PageDescriptionCard from 'components/parts/common/PageDescriptionCard'

export const ComparisonReportPage = ({ dispatch, location, campaign }) => {
  function toQueryObj(queryString) {
    return deserializeFromQueryString(queryString, { isNested: true })
  }

  function pushDefaultQueryObj(campaign) {
    // 初期表示時は全キャンペーン合計+配信中のキャンペーンをデフォルト表示
    const enabledCampaigns = campaign.items.filter(campaign => {
      if (campaign._camGroup === 'popup') {
        return campaign.enabled
      }
      return campaign.stage === 'in_the_middle'
    })
    const campaignOption = enabledCampaigns.map(cam => (
      {
        campaign: `${cam._camGroup}${rc.campaignTypeDelimiter}${cam.id}`,
        term: 'this_month',
      }
    ))
    const report = rc.defaultComparisonReportConditions.report.concat(campaignOption)
    dispatch(comparisonReportAction.searchComparisonReport({ ...rc.defaultComparisonReportConditions, report}))
  }

  useMountEffect(() => {
    dispatch(abandonedCampaignAction.fetchAbandonedCampaigns({ show_finished: true }))
    dispatch(completedCampaignAction.fetchCompletedCampaigns({ show_finished: true }))
    dispatch(contextualCampaignAction.fetchContextualCampaigns({ show_finished: true }))
    dispatch(popupAction.fetchPopups())
    dispatch(prospectsCampaignAction.fetchProspectsCampaigns({ show_finished: true }))
  })

  useEffect(() => {
    dispatch(comparisonReportAction.clearComparisonReports())
    if (queryObj !== null) {
      for (let i in queryObj.report) {
        dispatch(comparisonReportAction.fetchComparisonReport([i, queryObj.report[i]]))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search])

  const queryObj = toQueryObj(location.search)
  if (queryObj === null) {
    if (campaign.processResult === 'SUCCESS') {
      pushDefaultQueryObj(campaign)
    }
    return <></>
  }

  return (
    <ContentWrapper>
      <div className="content-heading">
        <div>比較レポート</div>
      </div>
      <PageDescriptionCard>
        各キャンペーンの配信成果（配信数、クリック数、売上額、コスト、ROAS など 12 指標）の⽐較が行えます。
      </PageDescriptionCard>
      <Card className="card-default">
        <CardBody>
          <ComparisonReportConditionForm queryObj={queryObj} />
        </CardBody>
      </Card>
      <div className="text-right mb-3">
        <ComparisonReportCsvDownloadButton />
      </div>
      <Card className="card-default">
        <CardBody>
          <ComparisonReportTable queryObj={queryObj} />
        </CardBody>
      </Card>
    </ContentWrapper>
  )
}

const select = ({
  abandonedCampaign, completedCampaign, contextualCampaign, popup, prospectsCampaign,
}) => {
  const abanItems = abandonedCampaign.items.map((x) => { x._camGroup = 'abandoned'; return x })
  const compItems = completedCampaign.items.map((x) => { x._camGroup = 'completed'; return x })
  const contItems = contextualCampaign.items.map((x) => { x._camGroup = 'contextual'; return x })
  const popItems = popup.items.map((x) => { x._camGroup = 'popup'; return x })
  const prosItems = prospectsCampaign.items.map((x) => { x._camGroup = 'prospects'; return x })

  return {
    campaign: {
      items: [...abanItems, ...compItems, ...contItems, ...popItems, ...prosItems],
      processResult: [abandonedCampaign.processResult, completedCampaign.processResult, contextualCampaign.processResult, popup.processResult, prospectsCampaign.processResult].every(processResult => processResult === 'SUCCESS') ? 'SUCCESS' : undefined
    },
    _isLoading: abandonedCampaign.isFetching || completedCampaign.isFetching || contextualCampaign.isFetching || popup.isFetching || prospectsCampaign.isFetching,
  }
}

export default connect(select)(ComparisonReportPage)
