import React from 'react'
import { connect } from 'react-redux'
import {
  Table, Button, FormGroup, Row, Col,
} from 'reactstrap'
import {
  mailAbTestAction, abandonedCampaignAction, completedCampaignAction, contextualCampaignAction,
} from 'actions'
import { Loading } from 'lib/components'
import { mailAbTestConstant } from 'config/constants'

export const ChangeMailAbTestStatusModal = ({
  mailAbTestProps, mailAbTestCampaignMap, campaign, dispatch,
}) => {
  const targetCampaigns = extractTargetCampaigns(mailAbTestCampaignMap.items, campaign.items)
  return (
    <Loading isLoading={campaign.isFetching || mailAbTestCampaignMap.isFetching}>
      <>
        <ModalTitleSegment mailAbTestProps={mailAbTestProps} />
        <TargetCampaignTable mailAbTestProps={mailAbTestProps} targetCampaigns={targetCampaigns} dispatch={dispatch} />
        <ModalButton mailAbTestProps={mailAbTestProps} targetCampaigns={targetCampaigns} dispatch={dispatch} />
      </>
    </Loading>
  )
}

/**
 * mailAbtestに対するステータス変更操作でモーダルのタイトルを決定する
 * @param mailAbTestProps:mailAbtestオブジェクト、willFinishedパラメータ（終了操作か否か）
 * @returns {XML}
 * @constructor
 */
const ModalTitleSegment = ({ mailAbTestProps }) => {
  let modalTitle; let
    modalCaption
  if (mailAbTestProps.willFinished) {
    modalTitle = mailAbTestConstant.modalTitle.finish
    modalCaption = mailAbTestConstant.modalCaption.finish
  } else
  if (mailAbTestProps.mailAbTest.status === 'in_preparation') {
    modalTitle = mailAbTestConstant.modalTitle.start
    modalCaption = mailAbTestConstant.modalCaption.start
  } else
  if (mailAbTestProps.mailAbTest.status === 'in_the_middle' && mailAbTestProps.mailAbTest.enabled) {
    modalTitle = mailAbTestConstant.modalTitle.pause
    modalCaption = mailAbTestConstant.modalCaption.pause
  } else
  if (mailAbTestProps.mailAbTest.status === 'in_the_middle' && !mailAbTestProps.mailAbTest.enabled) {
    modalTitle = mailAbTestConstant.modalTitle.resume
    modalCaption = mailAbTestConstant.modalCaption.resume
  }
  return (
    <div>
      <h3>{modalTitle}</h3>
      <p>{modalCaption}</p>
    </div>
  )
}

/**
 * モーダル上に表示するABテストに紐づくキャンペーン一覧を描画する
 * 終了操作の場合、配信を続行する採用ボタン付きのテーブルを描画する
 * @param targetCampaigns: ABテストに紐づくキャンペーン一覧
 * @param dispatch
 * @returns {XML}
 * @constructor
 */
const TargetCampaignTable = ({ mailAbTestProps, targetCampaigns, dispatch }) => {
  if (!mailAbTestProps.willFinished) {
    return (
      <Table responsive striped>
        <tbody>
          {
            targetCampaigns.map((cam, index) => (
              <tr key={`${cam._camGroup}${cam.id}`}>
                <td>{cam.name}</td>
              </tr>
            ))}
        </tbody>
      </Table>
    )
  }
  return (
    <Table responsive striped>
      <tbody>
        {
          targetCampaigns.map((cam, index) => (
            <tr key={`${cam._camGroup}${cam.id}`}>
              <td>{cam.name}</td>
              <td className="text-center">
                <ConfirtmAdoptCampaignButton adoptCampaign={cam} targetCampaigns={targetCampaigns} mailAbTest={mailAbTestProps.mailAbTest} dispatch={dispatch} />
              </td>
            </tr>
          ))}
      </tbody>
    </Table>
  )
}

/**
 * 配信続行するキャンペーンの採用ボタンを付与する
 * @param adoptCampaign 採用するキャンペーン
 * @param targetCampaigns
 * @param mailAbTest
 * @param dispatch
 * @returns {XML}
 * @constructor
 */
const ConfirtmAdoptCampaignButton = ({
  adoptCampaign, targetCampaigns, mailAbTest, dispatch,
}) => (
  <Button
    color="primary"
    size="lg"
    onClick={() => {
      finishMailAbTestAndRejectionCampaignsDisabled({
        adoptCampaign, targetCampaigns, mailAbTest, dispatch,
      })
    }}
  >
採用する
  </Button>
)

/**
 * モーダル状の実行・キャンセルボタン
 * @param mailAbTestProps
 * @param targetCampaigns
 * @param dispatch
 * @returns {XML}
 * @constructor
 */
const ModalButton = ({ mailAbTestProps, targetCampaigns, dispatch }) => {
  if (mailAbTestProps.willFinished) {
    return (
      <div className="text-left">
        <FormGroup>
          <Row>
            <Col md={3}>
              <Button
                color="secondary"
                size="lg"
                onClick={() => dispatch(mailAbTestAction.fetchMailAbTests())}
              >
キャンセル
              </Button>
            </Col>
            <Col md={3}>
              <Button
                color="danger"
                size="lg"
                onClick={() => { finishMailAbTestAndAllCampaignsDisabled(targetCampaigns, mailAbTestProps.mailAbTest, dispatch) }}
              >
全て不採用
              </Button>
            </Col>
          </Row>
        </FormGroup>
      </div>
    )
  }
  return (
    <div className="text-left">
      <FormGroup>
        <Row>
          <Col md={3}>
            <Button
              color="secondary"
              size="lg"
              onClick={() => dispatch(mailAbTestAction.fetchMailAbTests())}
            >
キャンセル
            </Button>
          </Col>
          <Col md={3}>
            <Button
              color="primary"
              size="lg"
              onClick={() => { changeMailAbTestAndCampaignStatus(mailAbTestProps.mailAbTest, targetCampaigns, dispatch) }}
            >
実行する
            </Button>
          </Col>
        </Row>
      </FormGroup>
    </div>
  )
}

/**
 * ABテストにひもづくキャンペーン一覧を抽出する
 * @param mailAbTestCampaignMap
 * @param campaign
 * @returns {Array}
 */
const extractTargetCampaigns = (mailAbTestCampaignMap, campaign) => {
  let targetCampaign = []
  if (mailAbTestCampaignMap.length > 0) {
    let mappedCampaignIds = mailAbTestCampaignMap.map(x => x.campaignId)
    targetCampaign = campaign.filter(x => mappedCampaignIds.includes(x.id))
    return targetCampaign
  }
  return targetCampaign
}


/**
 * ABテストと紐づくキャンペーンの配信ステータスを変更する
 * @param mailAbTest
 * @param targetCampaigns
 * @param dispatch
 */
const changeMailAbTestAndCampaignStatus = (mailAbTest, targetCampaigns, dispatch) => {
  let submitAction = defineSubmitCampaignAction(targetCampaigns[0]._camGroup)
  if (mailAbTest.status === 'in_the_middle' && mailAbTest.enabled) {
    for (let cam of targetCampaigns) {
      dispatch(submitAction({ ...cam, stage: 'stopped' }))
    }
    dispatch(mailAbTestAction.submitUpdateMailAbTest({ ...mailAbTest, enabled: false }))
    dispatch(mailAbTestAction.fetchMailAbTests())
  }
  if ((mailAbTest.status === 'in_preparation' || mailAbTest.status === 'in_the_middle') && !mailAbTest.enabled) {
    for (let cam of targetCampaigns) {
      dispatch(submitAction({ ...cam, stage: 'in_the_middle' }))
    }
    mailAbTest.status = 'in_the_middle'
    dispatch(mailAbTestAction.submitUpdateMailAbTest({ ...mailAbTest, enabled: true }))
    dispatch(mailAbTestAction.fetchMailAbTests())
  }
}

/**
 * ABテストを終了し不採用のキャンペーンを配信停止にするメソッド
 * @param adoptCampaign
 * @param targetCampaigns
 * @param mailAbTest
 * @param dispatch
 */
const finishMailAbTestAndRejectionCampaignsDisabled = ({
  adoptCampaign, targetCampaigns, mailAbTest, dispatch,
}) => {
  let submitAction = defineSubmitCampaignAction(adoptCampaign._camGroup)
  for (let cam of targetCampaigns) {
    if (cam.id === adoptCampaign.id) {
      dispatch(submitAction({ ...cam, stage: 'in_the_middle' }))
    } else {
      dispatch(submitAction({ ...cam, stage: 'stopped' }))
    }
  }
  mailAbTest.status = 'finished'
  dispatch(mailAbTestAction.submitUpdateMailAbTest({ ...mailAbTest, enabled: false }))
  dispatch(mailAbTestAction.fetchMailAbTests())
}

/**
 * ABテストを終了し対象キャンペーンを全て配信停止にするメソッド
 * @param targetCampaigns
 * @param mailAbTest
 * @param dispatchs
 */
const finishMailAbTestAndAllCampaignsDisabled = (targetCampaigns, mailAbTest, dispatch) => {
  let submitAction = defineSubmitCampaignAction(targetCampaigns[0]._camGroup)
  for (let cam of targetCampaigns) {
    dispatch(submitAction({ ...cam, stage: 'stopped' }))
  }
  mailAbTest.status = 'finished'
  dispatch(mailAbTestAction.submitUpdateMailAbTest({ ...mailAbTest, enabled: false }))
  dispatch(mailAbTestAction.fetchMailAbTests())
}

/**
 * キャンペーン種別ごとにsubmitActionを定義する
 * キャンペーン一覧画面にリダイレクトしてしまうためABテスト専用のActionを使う
 * @param campaignType
 * @returns {*}
 */
const defineSubmitCampaignAction = (campaignType) => {
  let submitAction
  switch (campaignType) {
  case 'abandoned':
    submitAction = abandonedCampaignAction.submitEnableAbandonedCampaign
    break
  case 'completed':
    submitAction = completedCampaignAction.submitEnableCompletedCampaign
    break
  case 'contextual':
    submitAction = contextualCampaignAction.submitEnableContextualCampaign
    break
  default:
  }
  return submitAction
}

const select = ({
  mailAbTestCampaignMap, abandonedCampaign, completedCampaign, contextualCampaign,
}, dispatch) => {
  const abanItems = abandonedCampaign.items.map((x) => { x._camGroup = 'abandoned'; return x })
  const compItems = completedCampaign.items.map((x) => { x._camGroup = 'completed'; return x })
  const contItems = contextualCampaign.items.map((x) => { x._camGroup = 'contextual'; return x })
  return {
    mailAbTestCampaignMap,
    campaign: {
      items: [...abanItems, ...compItems, ...contItems],
      isFetching: abandonedCampaign.isFetching || completedCampaign.isFetching || contextualCampaign.isFetching,
    },
    dispatch,
  }
}

export default connect(select)(ChangeMailAbTestStatusModal)
