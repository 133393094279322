import React from 'react'
import { connect } from 'react-redux'
import { Formik, Form } from 'formik'
import { Button, FormGroup } from 'reactstrap'
import * as Yup from 'yup'
import { Loading, FieldSingle, Label } from 'lib/components'
import { userAction } from 'actions'

const validationSchema = Yup.object().shape({
  lastName: Yup.string(),
  firstName: Yup.string(),
})

export const UserNameEditForm = ({ user, dispatch }) => (
  <Loading isLoading={user.isFetching}>
    <div className="mb-4">
      <h5>ユーザ情報は次の目的で利用します</h5>
      <ol>
        <li>作成したキャンペーンをテスト送信するため</li>
        <li>ご利用の当該サービスに係る各種ご連絡をするため</li>
        <li>株式会社DGビジネステクノロジーに係る各種ご連絡をするため</li>
      </ol>
    </div>
    <Formik
      initialValues={{
        lastName: user.item.lastName,
        firstName: user.item.firstName,
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        dispatch(userAction.submitUpdateCurrentUser(values))
      }}
    >
      {() => (
        <Form noValidate className="form-inline">
          <FormGroup className="mb-2 mr-3 mb-sm-0">
            <Label className="mr-2">姓</Label>
            <FieldSingle name="lastName" type="text" placeholder="ナビ" />
          </FormGroup>
          <FormGroup className="mb-2 mr-5 mb-sm-0">
            <Label className="mr-2">名</Label>
            <FieldSingle name="firstName" type="text" placeholder="太郎" />
          </FormGroup>
          <div className="text-center"><Button color="primary" type="submit">更新</Button></div>
        </Form>
      )}
    </Formik>
  </Loading>
)

const select = ({ user }) => ({ user })

export default connect(select)(UserNameEditForm)
