import { takeLatest, call, put } from 'redux-saga/effects'
import * as api from 'services/api'
import { lineCampaignsAction } from 'actions'
import { toast } from 'react-toastify'

function* handleFetchLineCampaigns() {
  const { payload } = yield call(api.fetchLineCampaigns)
  yield put(lineCampaignsAction.fetchLineCampaignsDone(payload))
}

function* handleSubmitChangeLineCampaignStatus(action) {
  const { payload, error } = yield call(api.submitChangeLineCampaignStatus, action.payload)
  if (error) {
    yield put(lineCampaignsAction.submitChangeLineCampaignStatusFailure(payload))
    toast.error('更新に失敗しました。' + payload)
  } else {
    yield put(lineCampaignsAction.submitChangeLineCampaignStatusSuccess(action.payload))
    if (action.payload.status === 'in_the_middle') {
      toast.success('キャンペーンの配信を開始しました')
    } else if (action.payload.status === 'stopped') {
      toast.success('キャンペーンを停止しました')
    } else {
      toast.success('更新しました')
    }
  }
}

function* handleSubmitFinishLineCampaign(action) {
  const { payload, error } = yield call(api.destroyLineCampaign, action.payload.id)
  if (error) {
    yield put(lineCampaignsAction.submitFinishLineCampaignFailure(payload))
    toast.error('更新に失敗しました。' + payload)
  } else {
    yield put(lineCampaignsAction.submitFinishLineCampaignSuccess(action.payload))
    toast.success('キャンペーンを終了しました')
  }
}

export const lineCampaignsSaga = [
  takeLatest(lineCampaignsAction.fetchLineCampaigns, handleFetchLineCampaigns),
  takeLatest(lineCampaignsAction.submitChangeLineCampaignStatus, handleSubmitChangeLineCampaignStatus),
  takeLatest(lineCampaignsAction.submitFinishLineCampaign, handleSubmitFinishLineCampaign),
]
