import React from 'react'
import moment from 'moment'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { push } from 'connected-react-router'
import {
  Table, Button, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown, CardBody, CardFooter,
} from 'reactstrap'
import {
  Loading, MailPreviewThumbnail, Pagination, Swal,
} from 'lib/components'
import { campaignConstant, generalConstant as gc } from 'config/constants'
import { arraysToObject } from 'lib/utils'
import { abandonedCampaignAction, completedCampaignAction, contextualCampaignAction } from 'actions'

export const CampaignList = ({
  campaign, company, limit = gc.campaignItemsLimit, offset = 0, campaignType = 'all', deliveryStatus = 'all', onChangeCopyCampaignIds, dispatch,
}) => {
  function scheduleText(campaign) {
    // 商品連動はターゲットアクションからの経過時間の設定がないため、放棄系と完了系のみ表示する
    if (campaign._camGroup === 'contextual') return null

    if (campaign.aiOptimizedStatus === 'not_optimized') {
      return <span>アクション発生から<strong>{displayedScheduleTime(campaign.schedule)}後</strong>に配信</span>
    } else {
      return <span>AI配信時間自動最適化設定</span>
    }
  }
  const targetItems = [...campaign.items].filter((cam, index) => campaignFilter(cam, campaignType, deliveryStatus))
  return (
    <Loading isLoading={campaign.isFetching}>
      <CardBody>
        <Table responsive>
          <tbody className="no-thead">
            {
              targetItems
                .sort((a, b) => campaignSort(a, b))
                // ページネーション用filter
                .filter((cam, index) => (index >= offset && index < offset + limit))
                .map(cam => (
                  <tr key={`${cam.campaignType}${cam.id}`}>
                    { company?.testAccount
                      && !!company?.parentCompany
                      && (
                        <td>
                          <input type="checkbox" value={cam.id} onChange={onChangeCopyCampaignIds} />
                        </td>
                      )
                    }
                    <td>
                      { cam.messageType !== 'line'
                        ? <MailPreviewThumbnail type={`${cam._camGroup}_campaigns`} id={cam.id} /> : <img src="/line.png" width="100px" alt="line" />
                      }
                    </td>
                    <td className="campaign-overview">
                      <h5>{cam.name}</h5>
                      <CampaignBadges campaign={cam} />
                      { scheduleText(cam) }
                      <p className="mb-0">
                        <span className="ascii">{moment(cam.updatedAt).format('YYYY/MM/DD HH:mm')}</span>に更新
                      </p>
                    </td>
                    <td className="text-center w-15">
                      <UncontrolledDropdown className="split-dropdown">
                        <Button tag={Link} to={`/campaign_edit/${cam._camGroup}/${cam.id}`} color="secondary" disabled={cam.stage === 'finished'}>編集</Button>
                        <DropdownToggle caret color="secondary" disabled={cam.stage === 'finished'} />
                        <DropdownMenu>
                          <DropdownItem onClick={(e) => { dispatch(push(`/campaign_new/${cam._camGroup}?original=${cam.id}`)) }}>コピーして新規作成</DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </td>
                    <td className="text-center w-10">
                      <FinishCampaignButton campaign={cam} dispatch={dispatch} />
                    </td>
                    <td className="text-center w-10">
                      <CampaignStageDropdownButton campaign={cam} dispatch={dispatch} />
                    </td>
                  </tr>
                ))
            }
          </tbody>
        </Table>
      </CardBody>
      <CardFooter>
        <Pagination
          totalItemCount={targetItems.length}
          limit={limit}
        />
      </CardFooter>
    </Loading>
  )
}

const purchaseHistory = arraysToObject(campaignConstant.purchaseHistories, 1, 0)
const gender = arraysToObject(campaignConstant.gender, 1, 0)
const customerType = arraysToObject(campaignConstant.customerTypes, 1, 0)
const abandonedType = arraysToObject(campaignConstant.abandonedTypes, 1, 0)
const completedType = arraysToObject(campaignConstant.completedTypes, 1, 0)
const contextualType = arraysToObject(campaignConstant.contextualTypes, 1, 0)

/**
 * バッジ（タグみたいなもの）を表示するComponent
 * @param {Object} campaign
 */

const CampaignBadges = ({ campaign }) => (
  <p className="mb-2">
    { campaign._camGroup === 'abandoned'
      && <span className="badge badge-purple">{abandonedType[campaign.abandonedType]}</span> }
    { campaign._camGroup === 'completed'
      && <span className="badge badge-purple">{completedType[campaign.completedType]}</span> }
    { campaign._camGroup === 'contextual'
      && <span className="badge badge-purple">{contextualType[campaign.triggerType]}</span> }
    { !!campaign.purchaseHistory
      && <span className="badge badge-purple">{purchaseHistory[campaign.purchaseHistory]}</span> }
    { !!campaign.gender
      && <span className="badge badge-purple">{gender[campaign.gender]}</span> }
    { !!campaign.customerType
      && <span className="badge badge-purple">{customerType[campaign.customerType]}</span> }
  </p>
)

/**
 * キャンペーンのステータス変更ボタンのComponent
 * @param {Object} campaign
 * @param {[type]} dispatch
 */

const CampaignStageDropdownButton = ({ campaign, dispatch }) => {
  let statusText; let actionText; let
    statusColor
  if (campaign.stage === 'in_preparation') {
    statusText = '準備中'
    statusColor = 'success'
    actionText = '開始する'
  }
  if (campaign.stage === 'in_the_middle') {
    statusText = '配信中'
    statusColor = 'info'
    actionText = '停止する'
  }
  if (campaign.stage === 'stopped') {
    statusText = '停止中'
    statusColor = 'warning'
    actionText = '開始する'
  }
  if (campaign.stage === 'finished') {
    statusText = '配信終了'
    statusColor = 'danger'
    actionText = '開始する'
  }

  // NOTICE: アクションの割当部分が密結合になっていて微妙
  // 本来は各アクションも引数に取った方がいいかなあ
  let submitAction
  switch (campaign._camGroup) {
  case 'abandoned':
    submitAction = abandonedCampaignAction.submitEnableAbandonedCampaign
    break
  case 'completed':
    submitAction = completedCampaignAction.submitEnableCompletedCampaign
    break
  case 'contextual':
    submitAction = contextualCampaignAction.submitEnableContextualCampaign
    break
  default:
  }
  const handleClick = () => {
    let newStage

    if (campaign.stage === 'in_preparation' || campaign.stage === 'stopped') {
      newStage = 'in_the_middle'
    } else if (campaign.stage === 'in_the_middle') {
      newStage = 'stopped'
    }

    dispatch(submitAction({
      ...campaign,
      stage: newStage
    })) 
  }
  return (
    <UncontrolledDropdown>
      <DropdownToggle
        caret
        disabled={campaign.stage === 'finished'}
        color={statusColor}
      >{statusText}
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem onClick={handleClick}>{actionText}</DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  )
}

const FinishCampaignButton = ({ campaign, dispatch }) => {
  let submitAction
  let payload
  switch (campaign._camGroup) {
  case 'abandoned':
    submitAction = abandonedCampaignAction.submitDeleteAbandonedCampaign
    payload = { abandonedCampaign: campaign }
    break
  case 'completed':
    submitAction = completedCampaignAction.submitDeleteCompletedCampaign
    payload = { completedCampaign: campaign }
    break
  case 'contextual':
    submitAction = contextualCampaignAction.submitDeleteContextualCampaign
    payload = { contextualCampaign: campaign }
    break
  default:
  }
  return (
    <Swal
      options={gc.finishCampaignSwalOptions}
      color="danger"
      callback={(isConfirm) => { isConfirm && dispatch(submitAction(payload)) }}
      disabled={campaign.stage === 'finished'}
    >
     終了
    </Swal>
  )
}

const bufferTime = 2 * 60

const displayedScheduleTime = (rawSchedule) => {
  if (rawSchedule < 60) {
    return `${rawSchedule}分`
  }
  if (rawSchedule < 60 * 24 - bufferTime) {
    return `${Math.ceil(rawSchedule / 60)}時間`
  }
  return `${Math.ceil(rawSchedule / (60 * 24))}日`
}

/**
 * ソート用関数
 * @param  {Object} a         比較対象キャンペーンA
 * @param  {Object} b         比較対象キャンペーンB
 * @param  {Object} condition ソート条件
 * @return {Boolean}          ソート判定結果
 */
const campaignSort = (a, b, condition) => moment(b.updatedAt) - moment(a.updatedAt)


const campaignFilter = (cam, campaignType, deliveryStatus) => {
  if (campaignType !== 'all' && cam._camGroup !== campaignType) { return false }
  if (deliveryStatus !== 'all') {
    if (deliveryStatus === 'on' && (cam.stage !== 'in_the_middle')) {
      return false
    }
    if (deliveryStatus === 'off' && (cam.stage === 'in_the_middle')) {
      return false
    }
  }
  return true
}

const select = ({
  abandonedCampaign, completedCampaign, contextualCampaign, company,
}) => {
  const abanItems = abandonedCampaign.items.map((x) => { x._camGroup = 'abandoned'; return x })
  const compItems = completedCampaign.items.map((x) => { x._camGroup = 'completed'; return x })
  const contItems = contextualCampaign.items.map((x) => { x._camGroup = 'contextual'; return x })
  return {
    campaign: {
      items: [...abanItems, ...compItems, ...contItems],
      isFetching: abandonedCampaign.isFetching || completedCampaign.isFetching || contextualCampaign.isFetching,
    },
    company: { ...company.item },
  }
}

export default connect(select)(CampaignList)
