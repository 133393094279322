import {
  call, put, takeLatest,
} from 'redux-saga/effects'
import { push } from 'connected-react-router'
import * as api from 'services/api'
import { lineCampaignFormAction } from 'actions'
import { toast } from 'react-toastify'

function* handleFetchLineCampaign(action) {
  const { payload, meta } = yield call(api.fetchLineCampaign, action.payload)
  yield put(lineCampaignFormAction.fetchLineCampaignDone(payload, meta))
}

function* handleCreateLineCampaign(action) {
  const { error } = yield call(api.createLineCampaign, action.payload)
  if (error) {
    yield put(lineCampaignFormAction.createLineCampaignFailure())
    toast.error('新規作成に失敗しました')
  } else {
    yield put(lineCampaignFormAction.createLineCampaignSuccess())
    toast.success('新規作成しました')
    yield put(push(`/line_campaign_list`))
  }
}

function* handleUpdateLineCampaign(action) {
  const { error } = yield call(api.updateLineCampaign, action.payload)
  if (error) {
    yield put(lineCampaignFormAction.updateLineCampaignFailure())
    toast.error('更新に失敗しました')
  } else {
    yield put(lineCampaignFormAction.updateLineCampaignSuccess())
    toast.success('更新しました')
    yield put(push(`/line_campaign_list`))
  }
}
export const lineCampaignFormSaga = [
  takeLatest(lineCampaignFormAction.fetchLineCampaign, handleFetchLineCampaign),
  takeLatest(lineCampaignFormAction.createLineCampaign, handleCreateLineCampaign),
  takeLatest(lineCampaignFormAction.updateLineCampaign, handleUpdateLineCampaign),
]
