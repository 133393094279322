import React, { useState } from 'react'
import { connect } from 'react-redux'
import {
  TabContent, TabPane, Nav, NavItem, NavLink, Table,
} from 'reactstrap'

const conditionalName = (campaignType) => {
  switch (campaignType) {
  case 'cart':
    return 'カートに入れた'
  case 'browser':
    return '閲覧した'
  case 'register':
    return '1. カート履歴 2. 閲覧履歴 3. 最新順の登録商品 の中から順に抽出した'
  case 'purchase':
    return '購入した'
  default:
    return ''
  }
}

const CampaignMailCodeHelp = ({ campaignType, messageType }) => {
  const [condition, setCondition] = useState('item')
  return (
    <div role="tabpanel">
      <Nav tabs>
        <NavItem>
          <NavLink
            className={[condition === 'item' ? 'active' : ''].join(' ')}
            onClick={() => { setCondition('item') }}
          >
            商品情報
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={[condition === 'user' ? 'active' : ''].join(' ')}
            onClick={() => { setCondition('user') } }
          >
            ユーザ属性
          </NavLink>
        </NavItem>
        {campaignType !== 'contextual'
          && (
            <NavItem>
              <NavLink
                className={[condition === 'multi_and_recommend' ? 'active' : ''].join(' ')}
                onClick={() => { setCondition('multi_and_recommend') }}
              >
                複数の商品・レコメンド情報
              </NavLink>
            </NavItem>
          )
        }
        {campaignType === 'cart'
          && (
            <NavItem>
              <NavLink
                className={[condition === 'cart' ? 'active' : ''].join(' ')}
                onClick={() => { setCondition('cart') }}
              >
                カート情報
              </NavLink>
            </NavItem>
          )
        }
      </Nav>
      <TabContent activeTab={condition} className="bg-white">
        <TabPane tabId="item">
          <Table striped bordered responsive>
            <thead>
              <tr>
                <th>コード</th>
                <th>データ取得元商品マスタ内カラム</th>
                <th>データ取得元コード内パラメータ</th>
                <th>説明</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{'{{ item.code }}'}</td>
                <td>code</td>
                <td>code</td>
                <td />
              </tr>
              <tr>
                <td>{'{{ item.name }}'}</td>
                <td>name</td>
                <td>name</td>
                <td>商品名</td>
              </tr>
              <tr>
                <td>{'{{ item.url1 }}'}</td>
                <td>url1</td>
                <td>url1</td>
                <td>商品URL</td>
              </tr>
              <tr>
                <td>{'{{ item.price1 }}'}</td>
                <td>price1</td>
                <td>price1</td>
                <td>価格</td>
              </tr>
              <tr>
                <td>{'{{ item.qty }}'}</td>
                <td>なし</td>
                <td>qty</td>
                <td>商品の個数</td>
              </tr>
              <tr>
                <td>{'{{ item.qty_price }}'}</td>
                <td>なし</td>
                <td>qtyPrice</td>
                <td>商品毎の⼩計額 5,000(price1)円 2(qty)個であれば、10,000（qtyPrice）円</td>
              </tr>
              <tr>
                <td>{'{{ item.price1 | commify }}'}</td>
                <td>なし</td>
                <td>なし</td>
                <td>価格表⽰（整数）の際に 3 桁区切りのカンマ（,）が⾃動で⼊る</td>
              </tr>
              <tr>
                <td>{'{{ item.stock_status }}'}</td>
                <td>stock_status</td>
                <td>stockStatus</td>
                <td>在庫状況 出し分け 0:在庫切れ 1:在庫あり 2:在庫僅少 3:⼀時的に在庫切れ</td>
              </tr>
              <tr>
                <td>{'{{ item.stock_count }}'}</td>
                <td>stock_count</td>
                <td>stockCount</td>
                <td>在庫残数</td>
              </tr>
              <tr>
                <td>{'{{ item.image_url1 }}'}</td>
                <td>image_url</td>
                <td>imageUrl1</td>
                <td>画像URL</td>
              </tr>
              <tr>
                <td>{'{{ item.sale_stage }}'}</td>
                <td>sale_stage</td>
                <td>saleStage</td>
                <td>販売状態</td>
              </tr>
              <tr>
                <td>{'{{ item.description }}'}</td>
                <td>description</td>
                <td>description</td>
                <td>説明文</td>
              </tr>
              <tr>
                <td>{'{{ order_code }}'}</td>
                <td>なし</td>
                <td>なし</td>
                <td>注文番号</td>
              </tr>
            </tbody>
          </Table>
        </TabPane>
        <TabPane tabId="user">
          <Table striped bordered responsive>
            <thead>
              <tr>
                <th>コード</th>
                <th>データ取得元 宛先マスタ内カラム</th>
                <th>データ取得元 コード内パラメータ</th>
                <th>説明</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{'{{ first_name }}'}</td>
                <td>first_name</td>
                <td>firstName</td>
                <td>名</td>
              </tr>
              <tr>
                <td>{'{{ last_name }}'}</td>
                <td>last_name</td>
                <td>lastName</td>
                <td>姓</td>
              </tr>
              <tr>
                <td>{'{{ full_name }}'}</td>
                <td>なし</td>
                <td>なし</td>
                <td>⽒名（last_name + first_name）をつめて 表⽰間にスペース無し</td>
              </tr>
              {messageType !== 'line' && (
                <tr>
                  <td>{'{{ email }}'}</td>
                  <td>email</td>
                  <td>email</td>
                  <td>メールアドレス</td>
                </tr>
              )}
              <tr>
                <td>{'{{ customer_code }}'}</td>
                <td>code</td>
                <td>userCode</td>
                <td>ユーザID</td>
              </tr>
              <tr>
                <td>{'{{ point }}'}</td>
                <td>point</td>
                <td>point</td>
                <td>ポイント数</td>
              </tr>
            </tbody>
          </Table>
        </TabPane>
        <TabPane tabId="multi_and_recommend">
          <Table striped bordered responsive>
            <thead>
              <tr>
                <th>コード</th>
                <th>レコメンドの種類</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  {'{% for item in items %}'}
                  <br />
                  &nbsp;&nbsp;
                  {'{{ item.name }}'}
                  <br />
                  {'{% endfor %}'}
                </td>
                <td>
                  { conditionalName(campaignType) }
                  商品
                </td>
              </tr>
              {campaignType === 'purchase'
              && (
                <tr>
                  <td>
                    {'{% for item in view_buy_items %}'}
                    <br />
                    &nbsp;&nbsp;
                    {'{{ item.name }}'}
                    <br />
                    {'{% endfor %}'}
                  </td>
                  <td>購入した商品をのぞく閲覧した商品</td>
                </tr>
              )
              }
              <tr>
                <td>
                  {'{% for item in recommend_vh.items %}'}
                  <br />
                  &nbsp;&nbsp;
                  {'{{ item.name }}'}
                  <br />
                  {'{% endfor %}'}
                </td>
                <td>上記抽出した商品x閲覧相関レコメンド</td>
              </tr>
              <tr>
                <td>
                  {'{% for item in recommend_ph.items %}'}
                  <br />
                  &nbsp;&nbsp;
                  {'{{ item.name }}'}
                  <br />
                  {'{% endfor %}'}
                </td>
                <td>上記抽出した商品x購入相関レコメンド</td>
              </tr>
              <tr>
                <td>
                  {'{%  for item in recommend_vr.items %}'}
                  <br />
                  &nbsp;&nbsp;
                  {'{{ item.name }}'}
                  <br />
                  {'{% endfor %}'}
                </td>
                <td>週間アクセスランキング</td>
              </tr>
              <tr>
                <td>
                  {'{%  for item in recommend_pr.items %}'}
                  <br />
                  &nbsp;&nbsp;
                  {'{{ item.name }}'}
                  <br />
                  {'{% endfor %}'}
                </td>
                <td>月間売上げランキング</td>
              </tr>
            </tbody>
          </Table>
        </TabPane>
        <TabPane tabId="cart">
          <Table striped bordered responsive>
            <thead>
              <tr>
                <th>コード</th>
                <th>パラメータ</th>
                <th>説明</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{'{{ total_qty }}'}</td>
                <td>item.qtyから自動で計算</td>
                <td>カート内の全商品の個数の合計</td>
              </tr>
              <tr>
                <td>{'{{ total_price }}'}</td>
                <td>totalPrice</td>
                <td>カート内の合計額</td>
              </tr>
            </tbody>
          </Table>
        </TabPane>
      </TabContent>
    </div>
  )
}

export default connect()(CampaignMailCodeHelp)
